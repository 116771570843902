import React from 'react'
import posed, { PoseGroup } from 'react-pose'

export default ({ children, location }) => {
  const RoutesContainer = posed.div({
    enter: {
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'easeIn'
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'easeOut'
      }
    }
  })

  return (
    <PoseGroup>
      <RoutesContainer key={location.pathname} style={{ margin: 0 }}>
        {children}
      </RoutesContainer>
    </PoseGroup>
  )
}
